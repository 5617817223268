export const FETCH_USERS = 'FETCH_USERS';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE';

export const FETCH_USER = 'FETCH_USER';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE';
export const SAVE_USER = 'SAVE_USER';
export const SAVE_USER_SUCCESS = 'SAVE_USER_SUCCESS';
export const SAVE_USER_FAILURE = 'SAVE_USER_FAILURE';

export const FETCH_COMPANIES = 'FETCH_COMPANIES';
export const FETCH_COMPANIES_SUCCESS = 'FETCH_COMPANIES_SUCCESS';
export const FETCH_COMPANIES_FAILURE = 'FETCH_COMPANIES_FAILURE';

export const FETCH_COMPANY = 'FETCH_COMPANY';
export const FETCH_COMPANY_SUCCESS = 'FETCH_COMPANY_SUCCESS';
export const FETCH_COMPANY_FAILURE = 'FETCH_COMPANY_FAILURE';
export const SAVE_COMPANY = 'SAVE_COMPANY';
export const SAVE_COMPANY_SUCCESS = 'SAVE_COMPANY_SUCCESS';
export const SAVE_COMPANY_FAILURE = 'SAVE_COMPANY_FAILURE';

export const LOGIN_INIT_START = 'LOGIN_INIT_START';
export const LOGIN_INIT_SUCCESS = 'LOGIN_INIT_SUCCESS';
export const LOGIN_INIT_FAILURE = 'LOGIN_INIT_FAILURE';

export const LOGIN_MFA_START = 'LOGIN_MFA_START';
export const LOGIN_MFA_SUCCESS = 'LOGIN_MFA_SUCCESS';
export const LOGIN_MFA_FAILURE = 'LOGIN_MFA_FAILURE';

export const SWITCH_COMPANY = 'SWITCH_COMPANY';
export const SWITCH_COMPANY_SUCCESS = 'SWITCH_COMPANY_SUCCESS';
export const SWITCH_COMPANY_FAILURE = 'SWITCH_COMPANY_FAILURE';

export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT = 'LOGOUT';
export const LOGOUT_MESSAGE = 'LOGOUT_MESSAGE';

export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_REQUEST_SUCCESS = 'RESET_PASSWORD_REQUEST_SUCCESS';
export const RESET_PASSWORD_REQUEST_FAILURE = 'RESET_PASSWORD_REQUEST_FAILURE';

export const FINISH_INSTALLATION_STARTED = 'FINISH_INSTALLATION_STARTED';
export const FINISH_INSTALLATION_SUCCESS = 'FINISH_INSTALLATION_SUCCESS';
export const FINISH_INSTALLATION_FAILURE = 'FINISH_INSTALLATION_FAILURE';

export const VALIDATE_RESET_PASSWORD_TOKEN = 'VALIDATE_RESET_PASSWORD_TOKEN';
export const VALIDATE_RESET_PASSWORD_TOKEN_SUCCESS = 'VALIDATE_RESET_PASSWORD_TOKEN_SUCCESS';
export const VALIDATE_RESET_PASSWORD_TOKEN_FAILURE = 'VALIDATE_RESET_PASSWORD_TOKEN_FAILURE';

export const SIGNUP = 'SIGNUP';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAILURE = 'SIGNUP_FAILURE';

export const REMOVE_USER_MFA_START = 'REMOVE_USER_MFA_START';
export const REMOVE_USER_MFA_SUCCESS = 'REMOVE_USER_MFA_SUCCESS';
export const REMOVE_USER_MFA_FAILED = 'REMOVE_USER_MFA_FAILED';

export const REVOKE_USER_SESSION_START = 'REVOKE_USER_SESSION_START';
export const REVOKE_USER_SESSION_SUCCESS = 'REVOKE_USER_SESSION_SUCCESS';
export const REVOKE_USER_SESSION_FAILED = 'REVOKE_USER_SESSION_FAILED';

export const START_REFRESH_TOKEN_REQUEST = 'START_REFRESH_TOKEN_REQUEST';
export const FINISH_REFRESH_TOKEN_REQUEST = 'FINISH_REFRESH_TOKEN_REQUEST';

export const START_REFRESH_TOKEN = 'START_REFRESH_TOKEN';
export const FINISH_REFRESH_TOKEN = 'FINISH_REFRESH_TOKEN';

export const SAVE_COLLECTION = 'SAVE_COLLECTION';
export const SAVE_COLLECTION_FAILURE = 'SAVE_COLLECTION_FAILURE';
export const SAVE_COLLECTION_SUCCESS = 'SAVE_COLLECTION_SUCCESS';

export const FETCH_COLLECTION = 'FETCH_COLLECTION';
export const FETCH_COLLECTION_SUCCESS = 'FETCH_COLLECTION_SUCCESS';
export const FETCH_COLLECTION_FAILURE = 'FETCH_COLLECTION_FAILURE';

export const CLEAR_COLLECTION = 'CLEAR_COLLECTION';

export const ADD_TO_COLLECTION = 'ADD_TO_COLLECTION';
export const ADD_TO_COLLECTION_SUCCESS = 'ADD_TO_COLLECTION_SUCCESS';
export const ADD_TO_COLLECTION_FAILURE = 'ADD_TO_COLLECTION_FAILURE';

export const FETCH_RELATED_COLLECTION = 'FETCH_RELATED_COLLECTION';
export const FETCH_RELATED_COLLECTION_SUCCESS = 'FETCH_RELATED_COLLECTION_SUCCESS';
export const FETCH_RELATED_COLLECTION_FAILURE = 'FETCH_RELATED_COLLECTION_FAILURE';

export const FETCH_TV_SHOW_EPISODES = 'FETCH_TV_SHOW_EPISODES';
export const FETCH_TV_SHOW_EPISODES_SUCCESS = 'FETCH_TV_SHOW_EPISODES_SUCCESS';
export const FETCH_TV_SHOW_EPISODES_FAILURE = 'FETCH_TV_SHOW_EPISODES_FAILURE';

export const REMOVE_COLLECTION = 'REMOVE_COLLECTION';
export const REMOVE_COLLECTION_SUCCESS = 'REMOVE_COLLECTION_SUCCESS';
export const REMOVE_COLLECTION_FAILURE = 'REMOVE_COLLECTION_FAILURE';

export const GET_INVOICING_COMPANIES = 'GET_INVOICING_COMPANIES';
export const GET_INVOICING_COMPANIES_SUCCESS = 'GET_INVOICING_COMPANIES_SUCCESS';
export const GET_INVOICING_COMPANIES_FAILURE = 'GET_INVOICING_COMPANIES_FAILURE';

export const CREATE_COLLECTION_FROM_ASSET = 'CREATE_COLLECTION_FROM_ASSET';
export const CREATE_COLLECTION_FROM_ASSET_SUCCESS = 'CREATE_COLLECTION_FROM_ASSET_SUCCESS';
export const CREATE_COLLECTION_FROM_ASSET_FAILURE = 'CREATE_COLLECTION_FROM_ASSET_FAILURE';

export const GET_ARCHIVE_ITEMS = 'archive-GET_ITEMS';
export const GET_ARCHIVE_ITEMS_SUCCESS = 'archive-GET_ARCHIVE_ITEMS_SUCCESS';
export const GET_ARCHIVE_ITEMS_FAILURE = 'archive-GET_ARCHIVE_ITEMS_FAILURE';

export const GET_ARCHIVE_ITEM = 'archive-GET_ITEM';
export const GET_ARCHIVE_ITEM_SUCCESS = 'archive-GET_ARCHIVE_ITEM_SUCCESS';
export const GET_ARCHIVE_ITEM_FAILURE = 'archive-GET_ARCHIVE_ITEM_FAILURE';

export const COLLECTION_OPERATION = 'COLLECTION_OPERATION';
export const COLLECTION_OPERATION_SUCCESS = 'COLLECTION_OPERATION_SUCCESS';
export const COLLECTION_OPERATION_FAILURE = 'COLLECTION_OPERATION_FAILURE';

export const DISABLE_API_KEY = 'DISABLE_API_KEY';
export const DISABLE_API_KEY_SUCCESS = 'DISABLE_API_KEY_SUCCESS';
export const DISABLE_API_KEY_FAILURE = 'DISABLE_API_KEY_FAILURE';

export const ENABLE_API_KEY = 'ENABLE_API_KEY';
export const ENABLE_API_KEY_SUCCESS = 'ENABLE_API_KEY_SUCCESS';
export const ENABLE_API_KEY_FAILURE = 'ENABLE_API_KEY_FAILURE';

export const REMOVE_API_KEY = 'REMOVE_API_KEY';
export const REMOVE_API_KEY_SUCCESS = 'REMOVE_API_KEY_SUCCESS';
export const REMOVE_API_KEY_FAILURE = 'REMOVE_API_KEY_FAILURE';

export const GENERATE_API_KEY = 'GENERATE_API_KEY';
export const GENERATE_API_KEY_SUCCESS = 'GENERATE_API_KEY_SUCCESS';
export const GENERATE_API_KEY_FAILURE = 'GENERATE_API_KEY_FAILURE';

export const SET_API_COMPANY_USER = 'SET_API_COMPANY_USER';
export const SET_API_COMPANY_USER_SUCCESS = 'SET_API_COMPANY_USER_SUCCESS';
export const SET_API_COMPANY_USER_FAILURE = 'SET_API_COMPANY_USER_FAILURE';

export const FETCH_SERVICE_USERS = 'FETCH_SERVICE_USERS';
export const FETCH_SERVICE_USERS_SUCCESS = 'FETCH_SERVICE_USERS_SUCCESS';
export const FETCH_SERVICE_USERS_FAILURE = 'FETCH_SERVICE_USERS_FAILURE';

export const GENERATE_SERVICE_USER = 'GENERATE_SERVICE_USER';
export const GENERATE_SERVICE_USER_SUCCESS = 'GENERATE_SERVICE_USER_SUCCESS';
export const GENERATE_SERVICE_USER_FAILURE = 'GENERATE_SERVICE_USER_FAILURE';

export const REMOVE_SERVICE_USER = 'REMOVE_SERVICE_USER';
export const REMOVE_SERVICE_USER_SUCCESS = 'REMOVE_SERVICE_USER_SUCCESS';
export const REMOVE_SERVICE_USER_FAILURE = 'REMOVE_SERVICE_USER_FAILURE';