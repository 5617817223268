import {
  FETCH_COMPANIES,
  FETCH_COMPANIES_SUCCESS,
  FETCH_COMPANIES_FAILURE,
  FETCH_COMPANY,
  FETCH_COMPANY_SUCCESS,
  FETCH_COMPANY_FAILURE,
  SAVE_COMPANY,
  SAVE_COMPANY_FAILURE,
  SAVE_COMPANY_SUCCESS,
  GENERATE_API_KEY,
  GENERATE_API_KEY_SUCCESS,
  GENERATE_API_KEY_FAILURE,
  FETCH_SERVICE_USERS,
  FETCH_SERVICE_USERS_SUCCESS,
  FETCH_SERVICE_USERS_FAILURE,
  GENERATE_SERVICE_USER,
  GENERATE_SERVICE_USER_SUCCESS,
  GENERATE_SERVICE_USER_FAILURE,
} from '../types';
import { actionTypes } from '../actions/companies';

const tableColums = [
  { columnName: 'logo', width: 100 },
  { columnName: 'id', width: 120 },
  { columnName: 'companyName', width: 200 },
  { columnName: 'address', width: 200 },
  { columnName: 'city', width: 180 },
  { columnName: 'postCode', width: 120 },
  { columnName: 'country', width: 180 },
  { columnName: 'vat', width: 100 },
];

export const companiesDefaultState = {
  error: {
    message: '',
    status: null,
  },
  loading: true,
  rows: [],
  count: 0,
  detail: {},
  apiKey: {},
  tablePreferences: {
    hiddenColumns: [],
    columnOrder: tableColums.map(({ columnName }) => columnName),
    columnWidths: tableColums,
    rowsPerPage: 10,
  },
  serviceUsers: {},
};

// eslint-disable-next-line default-param-last
export const reducer = (state = companiesDefaultState, action) => {
  switch (action.type) {
    case FETCH_COMPANIES:
    case SAVE_COMPANY:
    case SAVE_COMPANY_SUCCESS:
      return { ...state, error: {}, loading: true };
    case FETCH_COMPANIES_SUCCESS:
      return { ...state, ...action.payload, error: {}, loading: false };
    case FETCH_COMPANIES_FAILURE:
      return { ...state, rows: [], loading: false, error: action.payload.error.message };
    case FETCH_COMPANY:
      return { ...state, detail: {}, error: {}, loading: true };
    case FETCH_COMPANY_SUCCESS:
      return { ...state, detail: action.payload.company, error: {}, loading: false };
    case FETCH_COMPANY_FAILURE:
    case SAVE_COMPANY_FAILURE:
      return { ...state, detail: {}, loading: false, error: action.payload.error };
    case actionTypes.RESET_TABLE_PREFERENCES: {
      return {
        ...state,
        tablePreferences: companiesDefaultState.tablePreferences,
      };
    }
    case actionTypes.GET_TABLE_PREFERENCES_SUCCESS: {
      return {
        ...state,
        tablePreferences: action.payload,
      };
    }
    case actionTypes.SET_TABLE_PREFERENCES: {
      return {
        ...state,
        tablePreferences: { ...state.tablePreferences, [action.payload.key]: action.payload.value },
      };
    }
    case GENERATE_API_KEY:
      return { ...state, apiKey: { loading: true, error: false } };
    case GENERATE_API_KEY_SUCCESS:
      return { ...state, apiKey: { ...action.payload, loading: false, error: false } };
    case GENERATE_API_KEY_FAILURE:
      return { ...state, apiKey: { loading: false, error: action.payload.error } };
    case FETCH_SERVICE_USERS:
      return { ...state, serviceUsers: { loading: true, error: false } };
    case FETCH_SERVICE_USERS_SUCCESS:
      return { ...state, serviceUsers: { rows: action.payload, loading: false, error: false } };
    case FETCH_SERVICE_USERS_FAILURE:
      return { ...state, serviceUsers: { loading: false, error: action.payload.error } };
    case GENERATE_SERVICE_USER:
      return { ...state, serviceUser: { loading: true, error: false } };
    case GENERATE_SERVICE_USER_SUCCESS:
      return { ...state, serviceUser: { ...action.payload, loading: false, error: false } };
    case GENERATE_SERVICE_USER_FAILURE:
      return { ...state, serviceUser: { loading: false, error: action.payload.error } };

    default:
      return state;
  }
};

export default reducer;
